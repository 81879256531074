import React, { FC, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";

const Home: FC = () => {
	const { t } = useTranslation(["common"]);
	const { user } = useAuth0();
	const { isAuthenticated, loginWithRedirect } = useAuth0();

	useEffect(() => {
		if (!isAuthenticated) {
			loginWithRedirect();
		}
	}, [isAuthenticated]);

	return (
		<>
			<div className="w-full overflow-y-auto scroll-smooth">
				<div className="container">
					<div className="flex flex-col items-center justify-center min-h-screen">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								width: "100%",
								marginBottom: "2rem",
							}}
						>
							<Typography variant="headlineLarge">
								{t("hello_username", { username: user?.nickname })}
							</Typography>
							<Typography variant="titleSmall">Pathways CMH</Typography>
						</Box>
						<Typography paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Rhoncus dolor purus non enim praesent elementum facilisis leo vel.
							Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
							gravida rutrum quisque non tellus. Convallis convallis tellus id
							interdum velit laoreet id donec ultrices. Odio morbi quis commodo
							odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum
							est ultricies integer quis. Cursus euismod quis viverra nibh cras.
							Metus vulputate eu scelerisque felis imperdiet proin fermentum
							leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt
							lobortis feugiat vivamus at augue. At augue eget arcu dictum
							varius duis at consectetur lorem. Velit sed ullamcorper morbi
							tincidunt. Lorem donec massa sapien faucibus et molestie ac.
						</Typography>

						<Typography paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Rhoncus dolor purus non enim praesent elementum facilisis leo vel.
							Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
							gravida rutrum quisque non tellus. Convallis convallis tellus id
							interdum velit laoreet id donec ultrices. Odio morbi quis commodo
							odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum
							est ultricies integer quis. Cursus euismod quis viverra nibh cras.
							Metus vulputate eu scelerisque felis imperdiet proin fermentum
							leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt
							lobortis feugiat vivamus at augue. At augue eget arcu dictum
							varius duis at consectetur lorem. Velit sed ullamcorper morbi
							tincidunt. Lorem donec massa sapien faucibus et molestie ac.
						</Typography>
						<Typography paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Rhoncus dolor purus non enim praesent elementum facilisis leo vel.
							Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
							gravida rutrum quisque non tellus. Convallis convallis tellus id
							interdum velit laoreet id donec ultrices. Odio morbi quis commodo
							odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum
							est ultricies integer quis. Cursus euismod quis viverra nibh cras.
							Metus vulputate eu scelerisque felis imperdiet proin fermentum
							leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt
							lobortis feugiat vivamus at augue. At augue eget arcu dictum
							varius duis at consectetur lorem. Velit sed ullamcorper morbi
							tincidunt. Lorem donec massa sapien faucibus et molestie ac.
						</Typography>
						<Typography paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Rhoncus dolor purus non enim praesent elementum facilisis leo vel.
							Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
							gravida rutrum quisque non tellus. Convallis convallis tellus id
							interdum velit laoreet id donec ultrices. Odio morbi quis commodo
							odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum
							est ultricies integer quis. Cursus euismod quis viverra nibh cras.
							Metus vulputate eu scelerisque felis imperdiet proin fermentum
							leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt
							lobortis feugiat vivamus at augue. At augue eget arcu dictum
							varius duis at consectetur lorem. Velit sed ullamcorper morbi
							tincidunt. Lorem donec massa sapien faucibus et molestie ac.
						</Typography>
						<Typography paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Rhoncus dolor purus non enim praesent elementum facilisis leo vel.
							Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
							gravida rutrum quisque non tellus. Convallis convallis tellus id
							interdum velit laoreet id donec ultrices. Odio morbi quis commodo
							odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum
							est ultricies integer quis. Cursus euismod quis viverra nibh cras.
							Metus vulputate eu scelerisque felis imperdiet proin fermentum
							leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt
							lobortis feugiat vivamus at augue. At augue eget arcu dictum
							varius duis at consectetur lorem. Velit sed ullamcorper morbi
							tincidunt. Lorem donec massa sapien faucibus et molestie ac.
						</Typography>
						<Typography paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Rhoncus dolor purus non enim praesent elementum facilisis leo vel.
							Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
							gravida rutrum quisque non tellus. Convallis convallis tellus id
							interdum velit laoreet id donec ultrices. Odio morbi quis commodo
							odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum
							est ultricies integer quis. Cursus euismod quis viverra nibh cras.
							Metus vulputate eu scelerisque felis imperdiet proin fermentum
							leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt
							lobortis feugiat vivamus at augue. At augue eget arcu dictum
							varius duis at consectetur lorem. Velit sed ullamcorper morbi
							tincidunt. Lorem donec massa sapien faucibus et molestie ac.
						</Typography>
						<Typography paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Rhoncus dolor purus non enim praesent elementum facilisis leo vel.
							Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
							gravida rutrum quisque non tellus. Convallis convallis tellus id
							interdum velit laoreet id donec ultrices. Odio morbi quis commodo
							odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum
							est ultricies integer quis. Cursus euismod quis viverra nibh cras.
							Metus vulputate eu scelerisque felis imperdiet proin fermentum
							leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt
							lobortis feugiat vivamus at augue. At augue eget arcu dictum
							varius duis at consectetur lorem. Velit sed ullamcorper morbi
							tincidunt. Lorem donec massa sapien faucibus et molestie ac.
						</Typography>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
