import "./ListUser.css";
import IconButton from "@mui/material/IconButton";
import {
	InputAdornment,
	OutlinedInput,
	Stack,
	Typography,
} from "@mui/material";
import { Replay, Search } from "@mui/icons-material";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EnhancedTable from "./TableUser";

const programFilter = [
	{
		value: "All program",
		label: "All program",
	},
	{
		value: "Other",
		label: "Other",
	},
];
const ouFilter = [
	{
		value: "NorthCare Network",
		label: "NorthCare Network",
	},
	{
		value: "Other",
		label: "Other",
	},
];

export default function ListUser() {
	return (
		<>
			<div>
				<Typography variant="h5">User List</Typography>
			</div>
			<div className="divContainerTable">
				<Stack spacing={1.5} direction="row">
					<OutlinedInput
						className="inputSearch"
						placeholder="Search user"
						id="input-search-user"
						type={"text"}
						endAdornment={
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" edge="end">
									<Search />
								</IconButton>
							</InputAdornment>
						}
					/>

					<Button color="inherit" size="large" variant="outlined">
						<Replay />
					</Button>
					<Button color="primary" size="large" variant="contained">
						ADD
					</Button>
				</Stack>
				<div>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { marginRight: 1, width: "pt-1" },
						}}
						noValidate
						autoComplete="off"
					>
						<div className="divFilter">
							<TextField
								id="outlined-select-program"
								select
								label="Filter by program"
								defaultValue="All program"
							>
								{programFilter.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
							<TextField
								id="outlined-select-ou"
								select
								label="Filter by OU"
								defaultValue="NorthCare Network"
							>
								{ouFilter.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</div>
					</Box>
				</div>
				<EnhancedTable />
			</div>
		</>
	);
}
