import React, { FC, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import ListUser from "../components/Table";
import {
	useGetOrganizationList,
	useGetProgramList,
	useGetUsersApp,
} from "../hooks/useUser/useUser";

const UserPage: FC = () => {
	const { t } = useTranslation(["common"]);
	const { user } = useAuth0();
	const { isAuthenticated, loginWithRedirect } = useAuth0();
	const { data: dataOrganization } = useGetOrganizationList();

	const { data: dataProgram } = useGetProgramList(["663b5e04e91856fc8b400aaa"]);

	const { data: dataUser } = useGetUsersApp();
	console.log(dataOrganization);
	console.log(dataProgram);
	console.log(dataUser);
	useEffect(() => {
		if (!isAuthenticated) {
			loginWithRedirect();
		}
	}, [isAuthenticated]);
	return <ListUser />;
};

export default UserPage;
