import { AxiosInstance } from "axios";
import { ResponseError, ResponseService } from "./publicNalcamService";

const LOG_PREFIX = "protectedGetUsers:";

type IUser = {
	account: string;
	email: string;
	firstName: string;
	lastName: string;
	status: string;
	phoneNumber: string;
	npi: string;
};

export const createGetUsers = (instance: AxiosInstance) => {
	console.log("Start createGetUsers");
	console.log(instance.getUri());

	return (): Promise<IUser[]> => {
		return instance
			.get<ResponseService<IUser[]>>("/users")
			.then((res) => {
				return res.data.body;
			})
			.catch((err: ResponseError) => {
				console.error(LOG_PREFIX, err);
				return [] as IUser[];
			});
	};
};
