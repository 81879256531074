import { AxiosInstance } from "axios";
import { ResponseError, ResponseService } from "./publicNalcamService";

const LOG_PREFIX = "protectedGetPrograms:";

type IProgram = {
	id: string;
	name: string;
};

type Params = {
	orgUnits: string[];
};

export const createGetPrograms = (instance: AxiosInstance) => {
	console.log("Start createGetPrograms");

	return (params: Params): Promise<IProgram[]> => {
		return instance
			.get<ResponseService<IProgram[]>>(
				"/programs?organizationIds=" + params.orgUnits.join(","),
			)
			.then((res) => {
				return res.data.body;
			})
			.catch((err: ResponseError) => {
				console.error(LOG_PREFIX, err);
				return [] as IProgram[];
			});
	};
};
