import { AxiosInstance } from "axios";
import { ResponseError, ResponseService } from "./publicNalcamService";

const LOG_PREFIX = "protectedPostAudit:";

export const createPostAuditLog = (instance: AxiosInstance) => {
	console.log("Start createPostAuditLog");
	return (): Promise<any> => {
		return instance
			.get<ResponseService<any>>("/postaudit")
			.then((res) => res.data.body)
			.catch((err: ResponseError) => {
				console.error(LOG_PREFIX, err);
				return {};
			});
	};
};
