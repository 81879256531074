import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Providers from "./utils/provider";
import Home from "./pages";
import "./i18n/config";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "./components/Spinner";
import Layout from "./views/Layout";
import UserPage from "./pages/user";

function App() {
	const { isLoading, error } = useAuth0();

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (isLoading) {
		return (
			<div className="flex w-screen h-screen items-center justify-center">
				<Spinner />
			</div>
		);
	}

	return (
		<Providers>
			<Layout>
				<Router>
					<Routes>
						<Route path="/" element={<Home />} />
					</Routes>
					<Routes>
						<Route path="/user-list" element={<UserPage />} />
					</Routes>
				</Router>
			</Layout>
		</Providers>
	);
}

export default App;
