import axios, {
	AxiosError,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios";
import { createGetUsers } from "./protectedGetUsers";
import { createPostAuditLog } from "./protectedPostAuditLog";
import { createGetPrograms } from "./protectedGetPrograms";
import { createGetOrganizations } from "./protectedGetOrganizations";

const protectedInstance = axios.create({
	baseURL: process.env.REACT_APP_SIMS_PROTECTED_BACKEND_URL,
});

protectedInstance.defaults.headers["Content-Type"] = "application/json";

const requestInterceptor = function (config: InternalAxiosRequestConfig) {
	console.log(
		`AXIOS: [REQUEST][${config?.method?.toUpperCase()}] ${config.baseURL}${
			config.url
		}`,
		{
			payload: config?.data,
		},
	);
	return config;
};
const responseInterceptor = function (response: AxiosResponse) {
	console.debug(
		`AXIOS: [RESPONSE][${response?.config?.method?.toUpperCase()}] ${
			response.config?.baseURL
		}${response.config?.url}`,
		{
			response: response?.data,
			status: response?.status,
			statusText: response?.statusText,
		},
	);
	return response;
};
const defaultErrorHandler = function (error: AxiosError) {
	console.debug(`AXIOS: [ERROR] ${error.message}`, {
		error,
		response: error?.response?.data,
	});
	return Promise.reject(error);
};

if (process.env.REACT_APP_SIMS_AXIOS_DEBUG) {
	const axiosInstances = [protectedInstance];

	for (const axiosInstance of axiosInstances) {
		axiosInstance.interceptors.request.use(
			requestInterceptor,
			defaultErrorHandler,
		);
		axiosInstance.interceptors.response.use(
			responseInterceptor,
			defaultErrorHandler,
		);
	}
}

export const postAuditLog = createPostAuditLog(protectedInstance);

export const getPrograms = createGetPrograms(protectedInstance);
export const getOrganizations = createGetOrganizations(protectedInstance);
export const getUsers = createGetUsers(
	// createInstance({
	// 	baseURL: "http://10.0.2.2:13060",
	// }),
	protectedInstance,
);
