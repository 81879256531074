import {
	getOrganizations,
	getPrograms,
	getUsers,
} from "../../services/protectedNalcamService";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useGetLoggedUser = (
	userId: number,
	initialData = {},
): UseQueryResult<any> => {
	const userQuery = useQuery({
		queryKey: ["get-user-oath", userId],
		queryFn: () =>
			// fetch user data from API,
			initialData,
		staleTime: 1000 * 60 * 60, // 10 minutes,
	});

	return userQuery;
};

export const useGetUsersApp = (initialData = {}): UseQueryResult<any> => {
	const userQuery = useQuery({
		queryKey: ["get-user-list"],
		queryFn: async () => await getUsers(),
		initialData,
	});

	return userQuery;
};

export const useGetProgramList = (
	orgUnits: string[],
	initialData = {},
): UseQueryResult<any> => {
	const userQuery = useQuery({
		queryKey: ["get-program-list"],
		queryFn: async () => await getPrograms({ orgUnits }),
		initialData,
	});

	return userQuery;
};

export const useGetOrganizationList = (
	initialData = {},
): UseQueryResult<any> => {
	const userQuery = useQuery({
		queryKey: ["get-organizaation-list"],
		queryFn: async () => await getOrganizations(),
		initialData,
	});

	return userQuery;
};
