import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { FC, ReactNode, useState } from "react";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppTheme } from "../../utils/theme";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(2.5),
	marginTop: "82px",
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	height: "82px",
	display: "flex",
	flexDirection: "row",
	backgroundColor: "#fff",
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

type LayoutProps = {
	children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
	const { user, logout } = useAuth0();
	const theme = useAppTheme();
	const [open, setOpen] = useState(true);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const logoutWithRedirect = () =>
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});

	const handleDrawerOpen = () => setOpen(true);
	const handleDrawerClose = () => setOpen(false);

	return (
		<Box
			sx={{ display: "flex", backgroundColor: theme.palette.surface.main }}
			data-testid="layout"
		>
			<CssBaseline />
			<AppBar position="fixed" open={open} className="flex">
				<Toolbar className="flex-1 gap-4">
					<IconButton
						size="large"
						edge="start"
						aria-label="menu"
						data-testid="drawer-button"
						onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
					>
						<MenuIcon />
					</IconButton>

					<div className="flex flex-1 bg-surfaceCardsmall rounded-lg px-4 py-3 max-w-xl items-center justify-between h-12">
						<InputBase
							className="flex-1"
							placeholder="Search…"
							inputProps={{ "aria-label": "search" }}
						/>
						<SearchIcon className="text-icon-primary" />
					</div>

					<Button
						size="large"
						onClick={handleOpenUserMenu}
						color="primary"
						variant="contained"
						sx={{ textTransform: "none", height: "3rem", textAlign: "center" }}
					>
						REGISTER BENEFICIARY
					</Button>
				</Toolbar>

				<Box
					sx={{
						marginLeft: "auto",
						alignItems: "center",
						display: "flex",
						paddingLeft: "1rem",
						paddingRight: "1rem",
					}}
				>
					<Tooltip title="Notifications">
						<IconButton size="large">
							<NotificationsNoneOutlinedIcon className="text-icon-primary" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Open settings">
						<Button
							size="large"
							onClick={handleOpenUserMenu}
							sx={{
								textTransform: "none",
								width: "240px",
								justifyContent: "space-between",
							}}
							data-testid="user-button"
						>
							<div className="flex items-center gap-4 w-full">
								<div className="flex flex-col flex-1 justify-start items-start">
									<span className="text-base text-primary font-bold">
										{user?.nickname}
									</span>
									<span className="text-xs text-secondary">{user?.email}</span>
								</div>
								<KeyboardArrowDownIcon className="text-dark" />
							</div>
						</Button>
					</Tooltip>
					<Menu
						sx={{ mt: "45px" }}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
						data-testid="user-menu"
					>
						<MenuItem
							key={0}
							onClick={handleCloseUserMenu}
							data-testid="menu-item"
						>
							<Typography textAlign="center">Settings</Typography>
						</MenuItem>
						<MenuItem
							key={0}
							onClick={logoutWithRedirect}
							data-testid="menu-item-logout"
						>
							<Typography textAlign="center">Logout</Typography>
						</MenuItem>
					</Menu>
				</Box>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
						flexDirection: "column",
						backgroundColor: theme.palette.menu.main,
						height: "100vh",
						padding: "1.25rem 0.5rem",
						gap: "1rem",
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
				data-testid="drawer"
			>
				<div className="flex justify-center">
					<img
						src="assets/logo@2x.png"
						alt="MDHHS SIMS"
						className="w-48 h-36 mx-auto"
					/>
				</div>
			</Drawer>
			<Main open={open}>{children}</Main>
		</Box>
	);
};

export default Layout;
