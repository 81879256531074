import { AxiosInstance } from "axios";
import { ResponseError, ResponseService } from "./publicNalcamService";

const LOG_PREFIX = "protectedGetOrganizations:";

type IOrganization = {
	id: string;
	name: string;
};

export const createGetOrganizations = (instance: AxiosInstance) => {
	console.log("Start createGetOrganizations");

	return (): Promise<IOrganization[]> => {
		return instance
			.get<ResponseService<IOrganization[]>>("/organizations")
			.then((res) => {
				return res.data.body;
			})
			.catch((err: ResponseError) => {
				console.error(LOG_PREFIX, err);
				return [] as IOrganization[];
			});
	};
};
