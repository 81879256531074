import {
	createTheme,
	PaletteOptions,
	ThemeOptions,
	useTheme,
} from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

type CustomPaletteOptions = {
	main: string;
	light?: string;
	dark?: string;
	light2?: string;
	dark2?: string;
};

declare module "@mui/material/styles/createPalette" {
	interface Palette {
		surface: CustomPaletteOptions;
		accent: CustomPaletteOptions;
		icon: CustomPaletteOptions;
		outline: CustomPaletteOptions;
		menu: CustomPaletteOptions;
	}
	interface PaletteOptions {
		surface: CustomPaletteOptions;
		accent: CustomPaletteOptions;
		icon: CustomPaletteOptions;
		outline: CustomPaletteOptions;
		menu: CustomPaletteOptions;
	}
}

interface ExtendedPaletteOptions extends PaletteOptions {
	surface: CustomPaletteOptions;
	accent: CustomPaletteOptions;
	icon: CustomPaletteOptions;
	outline: CustomPaletteOptions;
	menu: CustomPaletteOptions;
}

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		display: true;
		headlineLarge: true;
		headlineSmall: true;
		titleMedium: true;
		titleSmall: true;
		bodyLarge: true;
		bodyMedium: true;
		bodySmall: true;
		labelLarge: true;
		labelMedium: true;
		labelSmall: true;
	}
}

interface ExtendedTypographyOptions extends TypographyOptions {
	display?: React.CSSProperties;
	headlineLarge?: React.CSSProperties;
	headlineSmall?: React.CSSProperties;
	titleMedium?: React.CSSProperties;
	titleSmall?: React.CSSProperties;
	bodyLarge?: React.CSSProperties;
	bodyMedium?: React.CSSProperties;
	bodySmall?: React.CSSProperties;
	labelLarge?: React.CSSProperties;
	labelMedium?: React.CSSProperties;
	labelSmall?: React.CSSProperties;
}

const theme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#6F3996",
			light: "#8A54B2",
			dark: "#52197B",
		},
		secondary: {
			main: "#4CB7A4",
			light: "#E7EFB8",
			dark: "#005757",
		},
		error: {
			main: "#AB0707",
			light: "#FCD6D0",
			dark: "#AB0707",
		},
		warning: {
			main: "#F8955B",
			light: "#FFE7E0",
			dark: "#F26716",
		},
		surface: {
			main: "#F7F4F9",
			light: "#FFFFFF",
			dark: "#E9D0F4",
		},
		accent: {
			main: "#52197B",
		},
		icon: {
			main: "#ADA6B3",
			light: "#D9D1DD",
			dark: "#4C4C56",
			dark2: "#52197B",
		},
		outline: {
			main: "#D9D1DD",
			light: "#E4DEE9",
			dark: "#ADA6B3",
			dark2: "#4C4C56",
		},
		menu: {
			main: "#52197B",
			light: "#8A54B2",
			light2: "#6F3996",
		},
	} as ExtendedPaletteOptions,
	typography: {
		display: {
			fontWeight: "900",
			fontSize: "4rem",
			lineHeight: "5.5rem",
		},
		headlineLarge: {
			fontWeight: "bold",
			fontSize: "3rem",
			lineHeight: "4rem",
		},
		headlineSmall: {
			fontWeight: "bold",
			fontSize: "1.5rem",
			lineHeight: "2rem",
		},
		titleMedium: {
			fontWeight: "500",
			fontSize: "1.25rem",
			lineHeight: "1.5rem",
		},
		titleSmall: {
			fontWeight: "500",
			fontSize: "18px",
			lineHeight: "1.25rem",
		},
		bodyLarge: {
			fontWeight: "normal",
			fontSize: "1rem",
			lineHeight: "1.5rem",
		},
		bodyMedium: {
			fontWeight: "normal",
			fontSize: "0.875rem",
			lineHeight: "1.25rem",
		},
		bodySmall: {
			fontWeight: "normal",
			fontSize: "0.75rem",
			lineHeight: "1rem",
		},
		labelLarge: {
			fontWeight: "300",
			fontSize: "1rem",
			lineHeight: "1.5rem",
		},
		labelMedium: {
			fontWeight: "300",
			fontSize: "0.75rem",
			lineHeight: "1.5rem",
		},
		labelSmall: {
			fontWeight: "300",
			fontSize: "11px",
			lineHeight: "1rem",
		},
	} as ExtendedTypographyOptions,
} as ThemeOptions);

export default theme;

export type AppTheme = typeof theme;

export const useAppTheme = () => useTheme<AppTheme>();
